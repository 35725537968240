import firebase from "firebase/app";
import { brand } from "../brandVariables/getBandVariables";
import { Challenge } from "../types/challengeDataResponse";

export const checkIfCurrentChallengeIsFinished = async ({
  nextChallenge,
}: {
  nextChallenge: Challenge;
}) => {
  const nextChallengeData = await firebase
    .firestore()
    .collection("challenges")
    .doc(nextChallenge.slug)
    .get();

  const data = nextChallengeData.data() as Challenge;

  if (data && data.status === "finished") {
    return true;
  }

  return false;
};
