import BaseUserAvatar from "../../../components/reusable/BaseUserAvatar";
import WinnerBanner from "../assets/winner_banner.png";
import { FC } from "react";
import { ClientProps } from "../../../hooks/useClientData";
import { WinnerResponse } from "../../../types/winnersResponse";
import { formattedTime } from "../../../helperFunctions/formatMsToTime";
import { brand } from "../../../brandVariables/getBandVariables";
export const WinnerCard: FC<{
  clientData: ClientProps;
  hasBanner?: boolean;
  winnerToUser?: WinnerResponse;
}> = ({ clientData, hasBanner = true, winnerToUser }) => {
  const winner = winnerToUser || clientData.winners?.data?.[0];
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";
  if (!winner) {
    return <></>;
  }

  return (
    <div className="winner">
      {hasBanner && (
        <div className={`winner__banner background ${isElgiganten}`}>
          <div className="background__angle-top-right"></div>
          <p>previous winners</p>
        </div>
      )}

      <div className="winner__info">
        <div className="winner__avatar">
          <BaseUserAvatar
            logoUrl={winner.userProfileLogoUrl}
            userName={winner.userName}
          />
        </div>

        <div className="winner__details">
          <p>{winner.userName}</p>
          <span>Team {winner.gameSpecificData?.teamName || "-"}</span>
        </div>

        <p>{formattedTime(winner.points)} </p>
      </div>
    </div>
  );
};
