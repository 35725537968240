import { ReactComponent as Austria } from "./maps/Austria.svg";
export const MapsList = {
  Melbourne: null,
  "Paul Ricard": null,
  Shanghai: null,
  "Sakhir (Bahrain)": null,
  Catalunya: null,
  Monaco: null,
  Montreal: null,
  Silverstone: null,
  Hockenheim: null,
  Hungaroring: null,
  Spa: null,
  Monza: null,
  Singapore: null,
  Suzuka: null,
  "Abu Dhabi": null,
  Texas: null,
  Brazil: null,
  Austria: Austria,
  Sochi: null,
  Mexico: null,
  "Baku (Azerbaijan)": null,
  "Sakhir Short": null,
  "Silverstone Short": null,
  "Texas Short": null,
  "Suzuka Short": null,
  Hanoi: null,
  Zandvoort: null,
  Imola: null,
  Portimão: null,
  Jeddah: null,
  Miami: null,
  "Las Vegas": null,
  Losail: null,
} as any;
