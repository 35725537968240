import brandLogo from "../../components/RACING/assets/Logitech logo.svg";
export const LOGITECH_brandVariables = {
  client: "LOGITECH", // For picking wich leaderboard to show
  layoutKeyValue: "LOGITECH",
  brand: "logitech", // For scss
  gameStyle: "racing", // For picking wich leaderboard to show
  eventCode: "logitech_gamebox_spring_2024", // Needed for fetching correct challenge
  // eventCode: "logitech_gamebox_spring_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "f12023",
  brandLogo: brandLogo,
  leaderboardLength: 7,
  prize: {
    nameOf: "logitech lightspeed ", // will be marked in color
    typeOfItem: "wireless gaming mouse", // will be added after nameOf
  },
};
