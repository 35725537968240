import { FC, useEffect, useState } from "react";
import { brand } from "../../../brandVariables/getBandVariables";
import { ClientProps } from "../../../hooks/useClientData";

export const Leaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const users = clientData.leaderboard.data;
  return (
    <div className="leaderboard">
      <div className="leaderboard__header">
        <h3>
          Win a <span>{brand.prize.nameOf}</span> {brand.prize.typeOfItem}!
        </h3>
        <h1>
          {clientData.challenge.data?.game} top <span>10</span>{" "}
        </h1>
      </div>
      <div className="leaderboard__container">
        <ul>
          {users &&
            users.map((user: any) => {
              return (
                <li
                  id={`player-${user.userChallengeData.rank}`}
                  className="leaderboard__user"
                >
                  <div className="user">
                    <span className="user__position">
                      {user.userChallengeData.rank}
                    </span>
                    {user.profile.profileLogoUrl &&
                    !user.profile.profileLogoUrl.includes("/static") ? (
                      <img src={user.profile.profileLogoUrl} alt="" />
                    ) : (
                      <h5>{user.profile.userName.slice(0, 1)}</h5>
                    )}
                    <div className="user__names">
                      <p className="user__name">{user.profile.userName}</p>
                      <span className="user__fullname">
                        {user.profile.fullName}
                      </span>
                    </div>
                    <span className="user__score">
                      {user.userChallengeData.points}
                    </span>
                  </div>
                </li>
              );
            })}

          {users &&
            users.length < 10 &&
            Array.from(Array(10 - (users.length + 1)), () => {
              return <li className="leaderboard__user placeholder" />;
            })}
        </ul>
        <div className="leaderboard__fade" />
      </div>
    </div>
  );
};
