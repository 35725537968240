import { useState, useEffect } from "react";
import firebase from "firebase/app"; // Assuming firebase is imported properly
import { brand } from "../brandVariables/getBandVariables";

export const useActiveChallenge = (client: string) => {
  const [loadingCurrentChallenge, setLoadingCurrentChallenge] = useState(true);
  const [currentActiveChallengeData, setCurrentActiveChallengeData] = useState<
    any | null
  >(null);
  const [lastClient, setLastClient] = useState<string>();

  const fetchData = async () => {
    setLastClient(client);

    try {
      setLoadingCurrentChallenge(true);
      console.log("Fetch current active challenge");

      const challengeData = await firebase
        .firestore()
        .collection("challenges")

        .where("status", "==", brand.challengeStatus)
        .where("eventCode", "==", brand.eventCode)
        .where("gameType", "==", brand.challengeGameType)
        // .where("slug", "==", "fortnite")
        .limit(1)
        .get();

      const challenge = challengeData.docs.map((doc) => doc.data())[0];

      if (challenge) {
        setCurrentActiveChallengeData(challenge);
      } else {
        setCurrentActiveChallengeData(null);
      }
    } catch (error) {
      console.error("Error fetching last active challenge:", error);
    } finally {
      setLoadingCurrentChallenge(false);
    }
  };

  useEffect(() => {
    if (currentActiveChallengeData) {
      return;
    } else {
      fetchData();
    }
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    if (client !== undefined) {
      if (currentActiveChallengeData && client !== lastClient) {
        fetchData();
      }
    }
  }, []);

  const fetchNewCurrentChallenge = () => {
    fetchData();
  };

  return {
    fetchNewCurrentChallenge,
    loadingCurrentChallenge,
    currentActiveChallengeData,
  };
};
