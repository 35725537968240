import { FC, useEffect, useState } from "react";
import { ClientProps } from "../hooks/useClientData";
import { brand } from "../brandVariables/getBandVariables";

// COUNTDOWN PAGE TO WHEN THE EVENT/FIRST CHALLENGE STARTS FOR THIS CLIENT

export const CountDown: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  const [time, setTime] = useState<string>("");
  const upcomingChallenges = clientData.nextChallenges.data;

  useEffect(() => {
    const timer = () => {
      if (!upcomingChallenges || upcomingChallenges.length === 0) {
        return;
      }
      const firstEventStartDate = new Date(
        upcomingChallenges[0].startDate.seconds * 1000
      );
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;

      let timer;
      var now = new Date();

      var distance = firstEventStartDate.getTime() - now.getTime();
      if (distance < 0) {
        clearInterval(timer);
        return;
      }
      const days = Math.floor(distance / _day);
      const hours = Math.floor((distance % _day) / _hour);
      const minutes = Math.floor((distance % _hour) / _minute);
      const seconds = Math.floor((distance % _minute) / _second);
      setUpdateTrigger((prev) => prev + 1);

      setTime(
        `${days < 10 ? 0 : ""}${days}:${hours < 10 ? 0 : ""}${hours}:${
          minutes < 10 ? 0 : ""
        }${minutes}:${seconds < 10 ? 0 : ""}${seconds}`
      );
      return;
    };
    setTimeout(() => {
      timer();
    }, 1000);
    if (time.length === 0) {
      timer();
    }
  }, [updateTrigger, upcomingChallenges]);

  return (
    <div>
      <div className="countdown">
        {/* <div className="countdown-brand-logo">{logo}</div> */}
        <p>Join us in</p>
        <h4>{time || "Soon"}</h4>
        {brand.prize.nameOf && brand.prize.typeOfItem && (
          <p>
            {" "}
            Win a <span>{brand.prize.nameOf}</span> {brand.prize.typeOfItem}!
          </p>
        )}
      </div>
    </div>
  );
};
