import { ReactComponent as Logo } from "../../assets/jbl-quantum-logo_white.svg";

export const JBL_CSTIMED_brandVariables = {
  client: "JBL",
  layoutKeyValue: "JBLCS2TIMED",
  brand: "jbl", // For scss
  gameStyle: "cs2timed", // For picking wich leaderboard to show
  eventCode: "jbl_dreamhack_summer_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "CSGO",
  brandLogo: Logo,
  prize: {
    nameOf: "JBL QUANTUM 910", // will be marked in color
    typeOfItem: "GAMING HEADSET", // will be added after nameOf
  },
};
