import { FC, useEffect } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import "./styles/index.scss";
import WinnerBanner from "./assets/winner_banner.png";
import { useTimer } from "../../hooks/useCountDown";
import { checkIfNextChallengeIsOngoing } from "../../helperFunctions/checkIfNextChallengeIsOngoing";
import firebase from "firebase/app";
import { TextWithLines } from "./components/TextWithLines/TextWithLines";
import { UserCard } from "./components/userCard/UserCard";
export const CS2TIMEDWaitingScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.nextChallenges?.data?.[0]?.startDate || undefined
  );

  const fetch = async () => {
    const nextChallenge = clientData.nextChallenges.data?.[0];
    const isGoingOn = await checkIfNextChallengeIsOngoing({
      nextChallenge: nextChallenge!,
    });

    return isGoingOn;
  };

  const setCurrentChallengeToOnGoing = async () => {
    await firebase
      .firestore()
      .collection("challenges")
      .doc(clientData.nextChallenges.data?.[0].slug)
      .update({
        status: "ongoing",
      })
      .then((res) => {
        console.log(res, "update");

        fetch().then((res) => {
          if (res) {
            window.location.reload();
          }
        });

        setInterval(async () => {
          if (clientData.nextChallenges.data?.[0]) {
            await fetch().then((res) => {
              if (res) {
                window.location.reload();
              }
            });
          }
        }, 30000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let hasRan;
    console.log(time, isTimeOver);

    if (
      !clientData.challenge.data &&
      !hasRan &&
      !clientData.nextChallenges.loading &&
      clientData.nextChallenges.data?.[0] &&
      isTimeOver
    ) {
      hasRan = true;

      setCurrentChallengeToOnGoing();
    }
  }, [time, isTimeOver]);
  const Logo = brand.brandLogo;
  return (
    <>
      <div className="waiting-screen">
        <section>
          <div className="waiting-screen__countdown">
            <div className="waiting-screen__heading">
              <div className="brand-logo">
                <Logo />
              </div>
            </div>
            {/* <h2>Join us in</h2> */}
            <TextWithLines text="cs2 challenge start in" />

            <section>
              <div className="time_section">
                <div className="time">
                  <p>{timeObj?.hours.replace(":", "")[0] || "-"}</p>
                  <p>{timeObj?.hours.replace(":", "")[1] || "-"}</p>
                </div>
                <div className="time_type">hrs</div>
              </div>
              <span />
              <div className="time_section">
                <div className="time">
                  <p>{timeObj?.minutes.replace(":", "")[0] || "-"}</p>{" "}
                  <p>{timeObj?.minutes.replace(":", "")[1] || "-"}</p>
                </div>
                <div className="time_type">mins</div>
              </div>
              <span />
              <div className="time_section">
                <div className="time">
                  <p>{timeObj?.seconds.replace(":", "")[0] || "-"}</p>{" "}
                  <p>{timeObj?.seconds.replace(":", "")[1] || "-"}</p>
                </div>
                <div className="time_type">Sec</div>
              </div>
            </section>
          </div>
        </section>

        {clientData.winners.data !== null &&
        clientData.winners.data.length !== 0 ? (
          <section>
            <div className="waiting-screen__winners">
              <div className="winners">
                <div className="winners__heading">
                  <p>previous winners</p>
                </div>
              </div>
              <ul>
                {clientData.winners.data.slice(0, 3).map((winner) => {
                  return (
                    <UserCard
                      user={{
                        profile: {
                          userName: winner.userName,
                          profileLogoUrl: winner.userProfileLogoUrl,
                        },
                        userChallengeData: {
                          points: winner.points,
                        },
                      }}
                      place={undefined}
                    />
                  );
                })}
              </ul>
            </div>
          </section>
        ) : (
          <div className="waiting-screen__countdown">
            <h3>Winner of each challenge gets a</h3>
            {brand.prize && (
              <h4>
                {brand.prize.nameOf} {brand.prize.typeOfItem}
              </h4>
            )}
          </div>
        )}
      </div>
    </>
  );
};
