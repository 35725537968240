import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { brand } from "../../brandVariables/getBandVariables";
import "./styles/index.scss";
import { useTimer } from "../../hooks/useCountDown";
import { checkIfNextChallengeIsOngoing } from "../../helperFunctions/checkIfNextChallengeIsOngoing";
import { EndScreen } from "../../pages/endScreen";
import { checkIfCurrentChallengeIsFinished } from "@/helperFunctions/checkIfCurrentChallengeIsOngoing";
import { LeaderboardIsEmpty } from "./components/leaderboards/LeaderboardIsEmpty";
import { TextWithLines } from "./components/TextWithLines/TextWithLines";
import { LeaderboardTopThree } from "./components/leaderboards/LeaderboardTopThree";
import { TimerComp } from "./components/TimerComp";
import { LeaderboardFull } from "./components/leaderboards/LeaderboardFull";

export const CS2TIMEDLeaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const hasActiveChallenge =
    clientData.leaderboard.data && !clientData.leaderboard.loading;

  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  const leaderboardLength = clientData.leaderboard.length as number;
  const [endScreen, setEndScreen] = useState<boolean>(false);

  const fetch = async () => {
    const nextChallenge = clientData.challenge.data as any;
    const isGoingOn = await checkIfNextChallengeIsOngoing({
      nextChallenge: nextChallenge!,
    });

    return isGoingOn;
  };

  useEffect(() => {
    let hasRan;
    if (!hasRan && !clientData.nextChallenges.loading && isTimeOver) {
      hasRan = true;

      if (
        isTimeOver &&
        (!clientData.nextChallenges.data ||
          clientData.nextChallenges.data?.length === 0)
      ) {
        setInterval(async () => {
          await checkIfCurrentChallengeIsFinished({
            nextChallenge: clientData.challenge.data,
          }).then((res) => {
            if (res) {
              clearTimer();
              setEndScreen(true);
              window.location.reload();
            }
          });
        }, 20000);
      }

      if (
        !endScreen &&
        clientData.nextChallenges.data &&
        clientData.nextChallenges.data[0]
      ) {
        setInterval(async () => {
          if (clientData.nextChallenges.data?.[0]) {
            await fetch().then((res) => {
              if (!res) {
                window.location.reload();
              }
            });
          }
        }, 20000);
      }
    }
  }, [time, isTimeOver]);

  if (endScreen) {
    return <EndScreen clientData={clientData} />;
  }

  const LeaderboardToRender = () => {
    // return <LeaderboardTopThree clientData={clientData} />;

    if (!leaderboardLength || leaderboardLength === 0) {
      return <LeaderboardIsEmpty />;
    }

    if (leaderboardLength <= 3) {
      return <LeaderboardTopThree clientData={clientData} />;
    }

    return <LeaderboardFull clientData={clientData} />;
  };

  const Logo = brand.brandLogo;

  return (
    <>
      {/* IF ACTIVE CHALLENGE*/}
      {hasActiveChallengeData && (
        <>
          <div className={`overview ${leaderboardLength === 0 ? "empty" : ""}`}>
            <div className="waiting-screen__heading">
              <div className="brand-logo">
                <Logo />
              </div>
            </div>
            <TextWithLines text="cs2 time trial" />
            <div className="overview__prize">
              <h4>
                set the fastest time - win a <span>JBL QUANTUM 910</span> gaming
                headset
              </h4>
            </div>
            <div
              className={`overview__content ${
                leaderboardLength === 0 ? "empty" : ""
              } ${leaderboardLength > 3 ? "full" : ""}`}
            >
              <section className="leaderboard">{LeaderboardToRender()}</section>
            </div>
            <div>
              <TimerComp time={time} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
