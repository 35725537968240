import { useState, useEffect } from "react";
import firebase from "firebase/app"; // Assuming firebase is imported properly
import { brand } from "../brandVariables/getBandVariables";
import { fullDays } from "../consts/weekDays";
import { log } from "firebase-functions/logger";

export const useWinners = (client: string) => {
  const [winnersData, setWinnersData] = useState<any[] | null>(null);
  const [loadingWinners, setLoadingWinners] = useState(true);
  const [lastClient, setLastClient] = useState<string>();

  const getWinners = async () => {
    setLastClient(client);
    console.log("getting winners");

    try {
      setLoadingWinners(true);
      const winnersTempArr = [] as any;

      const winnersQuerySnapshot = await firebase
        .firestore()
        .collection("event-winners")
        .doc(brand.eventCode)
        // .doc("jbl_dreamhack_winter_2023")
        .collection("winners")
        .get();
      console.log(winnersQuerySnapshot);

      winnersQuerySnapshot.forEach((doc) => {
        console.log(doc.data());

        winnersTempArr.push({
          ...doc.data(),
          winningDay:
            fullDays[
              new Date(doc.data().winningDate.seconds * 1000)
                .toDateString()
                .split(" ")[0] as string
            ],
        });
      });

      console.log(winnersTempArr);

      // Sort the winners by createdAt and limit to 2 entries
      const sortedWinners = winnersTempArr.sort(
        (a, b) => b.winningDate - a.winningDate
      );

      console.log(sortedWinners);

      setWinnersData(sortedWinners);
    } catch (error) {
      console.error("Error fetching winners:", error);
    } finally {
      setLoadingWinners(false);
    }
  };

  useEffect(() => {
    if (client !== undefined) {
      if (winnersData && client !== lastClient) {
        getWinners();
      }
    }
  }, []);

  useEffect(() => {
    if (winnersData) {
      return;
    }
    getWinners();

    // setInterval(() => {
    //   getWinners();
    // }, 90000);
  }, []);

  const fetchNewWinners = () => {
    getWinners();
  };

  return { fetchNewWinners, winnersData, loadingWinners };
};
