import { FC, useEffect, useState } from "react";
import { ClientProps } from "../../../../hooks/useClientData";
import { MapsList } from "../../../../consts/MapsLIst";
import { useTimer } from "../../../../hooks/useCountDown";
import { ReactComponent as StopWatch } from "../../../../assets/stopwatch.svg";
import { checkIfNextChallengeIsOngoing } from "../../../../helperFunctions/checkIfNextChallengeIsOngoing";
import { EndScreen } from "../../../../pages/endScreen";
import battleBus from "../../assets/battlebus.png";
import battleBusFade from "../../assets/battlebusFade.png";

export const LeaderboardIsEmpty: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const MapRender = MapsList["Austria"];

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  return (
    <div className="leaderboard__empty">
      <div className="empty">
        <div className="empty__meta">
          <p>Fortnite challenge is here!</p>
          <h1>Set the highest score</h1>

          <div className="empty__winPrize">
            <section>
              <h2>Win a super cool prize</h2>
            </section>
          </div>
        </div>
        <div className="empty__battleBus">
          <img src={battleBus} alt="" />
        </div>
        <div className="empty__battleBus empty__battleBus-fade">
          <img src={battleBusFade} alt="" />
        </div>
      </div>
    </div>
  );
};
