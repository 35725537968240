import { FC } from "react";
import { ClientProps } from "../hooks/useClientData";
import { EventDetails } from "./EventDetails";
import { CountDown } from "./countDown";

// WAITING SCREEN FOR WHEN A CHALLENGE HAS ENDED, AND THE UPCOMING ONE HAS NOT STARTED YET
export const WaitingScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  return (
    <div className="waiting-screen">
      <div className="waiting-screen__countdown">
        <CountDown clientData={clientData} />
      </div>
      <div className="waiting-screen__info">
        <EventDetails clientData={clientData} />
      </div>
    </div>
  );
};
