import { FC } from "react";
import { Leaderboard } from "./components/leaderboard";
import TopThree from "./components/topthree";
import { logo } from "./assets/logo";
import { ClientProps } from "../../hooks/useClientData";
import "./styles/index.scss";
import { brand } from "../../brandVariables/getBandVariables";

export const JBLLeaderboard: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const hasActiveChallenge =
    clientData.leaderboard.data && !clientData.leaderboard.loading;

  const hasActiveChallengeData =
    clientData.hasActiveChallenge && clientData.leaderboard.data;

  const leaderboardLength = clientData.leaderboard.data?.length;

  const LeaderboardToRender = () => {
    if (!leaderboardLength || leaderboardLength === 0) {
      // Return empty leaderboard state
    }

    if (leaderboardLength === 1) {
      // Return leaderboard state for 1 user
    }

    if (leaderboardLength === 2) {
      // Return leaderboard state for 2 users
    }

    if (leaderboardLength === 3) {
      // Return leaderboard state for 3 users
    }

    // Return leaderboard state for all users
    return <Leaderboard clientData={clientData} />;
  };

  return (
    <>
      {/* IF ACTIVE CHALLENGE*/}
      {hasActiveChallenge && (
        <div className="brand-logo">{brand.brandLogo}</div>
      )}
      {hasActiveChallengeData && (
        <>
          <div className="overview">
            {LeaderboardToRender()}
            <Leaderboard clientData={clientData} />
          </div>
        </>
      )}
    </>
  );
};
