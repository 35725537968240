import { useNextChallenges } from "./useNextChallengeData";
import { useActiveChallenge } from "./useCurrentChallengeData";
import { useWinners } from "./useChallengeWinnersData";
import { useLeaderboardData } from "./useLeaderboardData";
import { useEffect, useState } from "react";
import { Challenge } from "../types/challengeDataResponse";
import { leaderboardUsers } from "../types/leaderboardUsersResponse";
import { WinnerResponse } from "../types/winnersResponse";
import { useRealtimeDBLeaderboard } from "./useRealtimeLeaderboard";
import { auth } from "../BE/firebaseConfig";

export type ClientProps = {
  leaderboard: {
    loading: boolean;
    data: leaderboardUsers[] | null;
    function: () => void;
    length: number;
  };

  winners: {
    loading: boolean;
    data: WinnerResponse[] | null;
    function: () => void;
  };

  challenge: {
    loading: boolean;
    data: Challenge | null;
    function: () => void;
  };

  nextChallenges: {
    loading: boolean;
    data: Challenge[] | null;
    function: () => void;
  };
  hasActiveChallenge: boolean;
};

export const useClientData = (client: string) => {
  const currentUser = auth.currentUser;
  // Upcoming challenge list
  const { fetchNewNextChallenge, loadingNextChallenge, nextChallenges } =
    useNextChallenges(client);

  // fetch current challenge that is active
  const {
    fetchNewCurrentChallenge,
    loadingCurrentChallenge,
    currentActiveChallengeData,
  } = useActiveChallenge(client);

  //   Fetch winners
  const { fetchNewWinners, winnersData, loadingWinners } = useWinners(client);

  //   Fetch leaderboard data
  // const { fetchNewLeaderboard, leaderboardData, loadingLeaderboard } =
  //   useLeaderboardData(client);

  const { loadingRealtimeLeaderboard, leaderboardData, fetchAllLeaderboard } =
    useRealtimeDBLeaderboard({
      slug: currentActiveChallengeData?.slug,
      // slug: "logitech-f1-saturday-2",
    });

  // Set init data (mostly for loading states)
  const [clientData, setClientData] = useState<ClientProps>({
    leaderboard: {
      loading: loadingRealtimeLeaderboard,
      data: leaderboardData,
      function: fetchAllLeaderboard,
      length: leaderboardData.length,
    },

    winners: {
      loading: loadingWinners,
      data: winnersData,
      function: fetchNewWinners,
    },

    challenge: {
      loading: loadingCurrentChallenge,
      data: currentActiveChallengeData,
      function: fetchNewCurrentChallenge,
    },

    nextChallenges: {
      loading: loadingNextChallenge,
      data: nextChallenges,
      function: fetchNewNextChallenge,
    },
    hasActiveChallenge: currentActiveChallengeData?.slug !== undefined,
  });

  // Update if any change
  useEffect(() => {
    setClientData({
      leaderboard: {
        loading: loadingRealtimeLeaderboard,
        data: leaderboardData,
        function: fetchAllLeaderboard,
        length: leaderboardData.length,
      },

      winners: {
        loading: loadingWinners,
        data: winnersData,
        function: fetchNewWinners,
      },

      challenge: {
        loading: loadingCurrentChallenge,
        data: currentActiveChallengeData,
        function: fetchNewCurrentChallenge,
      },

      nextChallenges: {
        loading: loadingNextChallenge,
        data: nextChallenges,
        function: fetchNewNextChallenge,
      },
      hasActiveChallenge: currentActiveChallengeData?.slug !== undefined,
    });
  }, [
    loadingRealtimeLeaderboard,
    loadingWinners,
    loadingCurrentChallenge,
    loadingNextChallenge,
    currentUser,
    leaderboardData,
  ]);

  // Build data for ease of use later base on clien;
  return { clientData };
};
