import { useState, useRef, useCallback, useEffect } from "react";
import { useActiveChallenge } from "./useCurrentChallengeData";
import { brand } from "../brandVariables/getBandVariables";

export const useTimer = (timeInSeconds: any) => {
  const [time, setTime] = useState<string | null>(null);
  const [timeObj, setTimeObj] = useState<any>();
  const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
  let timerRef;

  const {
    fetchNewCurrentChallenge,
    loadingCurrentChallenge,
    currentActiveChallengeData,
  } = useActiveChallenge(brand.client);

  useEffect(() => {
    if (time && /^0+$/.test((time as any).replaceAll(":", ""))) {
      setIsTimeOver(true);
    } else {
      setIsTimeOver(false);
    }
  }, [time]);

  const timer = useCallback(() => {
    if (!timeInSeconds) {
      return "- - - -";
    }

    const firstEventStartDate = new Date(timeInSeconds.seconds * 1000);
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    var now = new Date();
    var distance = firstEventStartDate.getTime() - now.getTime();

    if (distance < 0) {
      clearInterval(timerRef);
      setTimeObj({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      });
      return setTime(`00:00:00`);
    }

    const days = Math.floor(distance / _day);
    const hours = Math.floor((distance % _day) / _hour);
    const minutes = Math.floor((distance % _hour) / _minute);
    const seconds = Math.floor((distance % _minute) / _second);

    const daysString = days ? `${days < 10 ? 0 : ""}${days}:` : "00:";
    const hoursString = hours ? `${hours < 10 ? 0 : ""}${hours}:` : "00:";
    const minutesString = minutes
      ? `${minutes < 10 ? 0 : ""}${minutes}:`
      : "00:";
    const secondsString = seconds ? `${seconds < 10 ? 0 : ""}${seconds}` : "00";

    setTimeObj({
      days: daysString,
      hours: hoursString,
      minutes: minutesString,
      seconds: secondsString,
    });

    setTime(`${hoursString}${minutesString}${secondsString}` as any);
  }, [timeInSeconds]);

  const startTimer = useCallback(() => {
    if (timerRef) {
      clearInterval(timerRef);
    }

    timerRef = setInterval(() => {
      timer();
    }, 1000);

    timer();
  }, [timer, time]);

  useEffect(() => {
    if (timerRef) {
      clearInterval(timerRef);
    }
    setInterval(() => {
      timer();
    }, 1000);
  }, [timeInSeconds]);

  const clearTimer = () => {
    clearInterval(timerRef);
  };

  return { time, startTimer, timeObj, isTimeOver, clearTimer };
};
