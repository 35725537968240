import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { getAuthorProfileBasedOnUid } from "../helperFunctions/getUserByUid";
import { cacheDatabase } from "../BE/firebaseConfig";

export const useRealtimeDBLeaderboard = ({ slug }: { slug: string }) => {
  const [loadingRealtimeLeaderboard, setLoadingRealtimeLeaderboard] =
    useState<boolean>(false);
  const [leaderboardData, setLeaderboardData] = useState<any[]>([]);
  const databaseRef = cacheDatabase.ref("leaderboard");
  console.log(slug);

  const fetchAllLeaderboard = async () => {
    setLoadingRealtimeLeaderboard(true);
    databaseRef
      .child(slug)
      .orderByKey()
      .on("value", async (snapshot) => {
        console.log("DB active");

        console.log(snapshot.val());

        if (snapshot.val() && snapshot.val().leaderboard) {
          const newLeaderboardDataArray: any[] = [...leaderboardData];
          const sortedArray = snapshot.val().leaderboard;
          await Promise.all(
            sortedArray.map(async (val: any, index) => {
              let userProfile;
              console.log(val, index, userProfile);

              const authorProfile = await getAuthorProfileBasedOnUid(
                val.userId,
                "WOG"
              );
              console.log(authorProfile, index);

              if (authorProfile) {
                userProfile = authorProfile;
              } else {
                const authorProfile2 = await getAuthorProfileBasedOnUid(
                  val.userId,
                  "ELGIGANTEN"
                );
                console.log(authorProfile2, index);

                if (authorProfile2) {
                  userProfile = authorProfile2;
                }
              }

              if (userProfile) {
                newLeaderboardDataArray.push({
                  profile: userProfile,
                  userChallengeData: val.userChallengeData,
                });
              } else if (!userProfile && val.userChallengeData) {
                newLeaderboardDataArray.push({
                  profile: {
                    fullName: "",
                    slug: "",
                    uid: "",
                    userName: "Failed to load user's info",
                  },
                  userChallengeData: val.userChallengeData,
                });
              }
              userProfile = undefined;
            })
          );
          setLoadingRealtimeLeaderboard(false);
          setLeaderboardData(
            newLeaderboardDataArray.sort(
              (a, b) => a.userChallengeData.rank - b.userChallengeData.rank
            )
          );
        } else {
          setLoadingRealtimeLeaderboard(false);
        }
      });

    return;
  };

  useEffect(() => {
    // Clean up the Firebase Realtime Database listener when component unmounts
    return () => {
      //   databaseRef.off(); // This removes all event listeners at the reference
    };
  }, [slug]); // Run effect only when slug changes

  useEffect(() => {
    if (leaderboardData.length === 0 && slug) {
      setLoadingRealtimeLeaderboard(true);
      fetchAllLeaderboard();
    }
  }, [slug]);

  return { loadingRealtimeLeaderboard, leaderboardData, fetchAllLeaderboard };
};
