import { FC, useEffect } from "react";
import "./styles/index.scss";
import { ClientProps } from "../../hooks/useClientData";
import { checkIfNextChallengeIsOngoing } from "../../helperFunctions/checkIfNextChallengeIsOngoing";
import { useTimer } from "../../hooks/useCountDown";
import { brand } from "../../brandVariables/getBandVariables";
// import { WinnerCard } from "@components/RACING/components/WinnerCard";

import FortniteTimerIcon from "./assets/timer-icon-fortnite.png";
import { WinnerCard } from "./components/WinnerCard";
export const FORTNITEWaitingScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }): JSX.Element => {
  const { time, startTimer, timeObj } = useTimer(
    clientData.nextChallenges?.data?.[0]?.startDate || undefined
  );

  const fetch = async () => {
    const nextChallenge = clientData.nextChallenges.data?.[0];
    const isGoingOn = await checkIfNextChallengeIsOngoing({
      nextChallenge: nextChallenge!,
    });

    return isGoingOn;
  };

  useEffect(() => {
    let hasRan;
    if (
      !clientData.challenge.data &&
      !hasRan &&
      !clientData.nextChallenges.loading &&
      clientData.nextChallenges.data?.[0] &&
      time &&
      /^0+$/.test((time as any).replaceAll(":", ""))
    ) {
      hasRan = true;

      setInterval(async () => {
        if (clientData.nextChallenges.data?.[0]) {
          await fetch().then((res) => {
            if (res) {
              window.location.reload();
            }
          });
        }
      }, 30000);
    }
  }, [time]);

  return (
    <>
      <div className="waiting-screen">
        <section>
          <div className="waiting-screen__countdown">
            <div className="waiting-screen__heading">
              {/* <div className="brand-logo"> */}
              {/* <img src={brand.brandLogo} alt="" /> */}
              {/* </div> */}
            </div>
            <h2>fortnite challenge in</h2>
            <section>
              <div>
                <p>
                  {timeObj?.hours.replace(":", "")[0] || "-"}
                  {timeObj?.hours.replace(":", "")[1] || "-"}
                </p>
                <small>hrs</small>
              </div>
              <span />
              <div>
                <p>
                  {timeObj?.minutes.replace(":", "")[0] || "-"}{" "}
                  {timeObj?.minutes.replace(":", "")[1] || "-"}
                </p>
                <small>mins</small>
              </div>
              <span />
              <div>
                <p>
                  {timeObj?.seconds.replace(":", "")[0] || "-"}
                  {timeObj?.seconds.replace(":", "")[1] || "-"}
                </p>
                <small>sec</small>
              </div>
            </section>
            <div className="card__prize">
              <h3>Win a super cool prize</h3>
            </div>
            <div className="waiting-screen__clock-icon">
              <img src={FortniteTimerIcon} alt="" />
            </div>
          </div>
        </section>

        {clientData.winners.data !== null &&
        clientData.winners.data.length !== 0 ? (
          <section>
            <div className="waiting-screen__winners">
              <span>previous winners</span>

              <ul>
                {clientData.winners.data.slice(0, 4).map((winner) => {
                  return (
                    <WinnerCard
                      winnerToUser={winner}
                      clientData={clientData}
                      hasBanner={false}
                    />
                  );
                })}
              </ul>
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
