import { FC } from "react";
import { ReactComponent as QrBg } from "./assets/qr_bg.svg";
import QRCode from "react-qr-code";
import { brand } from "@/brandVariables/getBandVariables";
import { ClientProps } from "@/hooks/useClientData";

export const RACING_qrCode: FC<{
  clientData: ClientProps;
}> = ({ clientData }): JSX.Element => {
  const nextChallenge = clientData?.nextChallenges?.data?.[0];
  return (
    <div className="qr-code">
      <section>
        <h1>scan this qr code</h1>
        <h2>
          Drive the fastest lap time and win <span>{brand.prize.nameOf}</span>{" "}
        </h2>
        <div className="qr-code__code">
          <p>scan to join the f1 challenge</p>
          <div className="code">
            <div className="code__scanable">
              <QRCode value="ello" />
            </div>
            <div className="code__backgorund">
              <QrBg />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
