import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { brand } from "../brandVariables/getBandVariables";
import { Challenge } from "../types/challengeDataResponse";

export const useNextChallenges = (client: string) => {
  const [loadingNextChallenge, setLoadingNextChallenge] = useState(true);
  const [nextChallenges, setNextChallenges] = useState<Challenge[] | null>(
    null
  );
  const [lastClient, setLastClient] = useState<string>();
  const fetchData = async () => {
    setLastClient(client);
    try {
      setLoadingNextChallenge(true);
      const nextChallengeQuery = await firebase
        .firestore()
        .collection("challenges")
        .where("client", "==", brand.client)
        // .where("client", "==", "WOG")
        .where("status", "==", "upcoming")
        .get();

      if (nextChallengeQuery.docs.length !== 0) {
        const nextChallengesData = nextChallengeQuery.docs.map((doc) =>
          doc.data()
        ) as any[];

        nextChallengesData.sort((a, b) => {
          return a.startDate.seconds * 1000 - b.startDate.seconds * 1000;
        });

        setNextChallenges(nextChallengesData);
      }
      setLoadingNextChallenge(false);
    } catch (error) {
      setLoadingNextChallenge(false);

      console.error("Error fetching next challenges:", error);
    } finally {
      setLoadingNextChallenge(false);
    }
  };

  useEffect(() => {
    if (nextChallenges && client !== lastClient) {
      fetchData();
      setInterval(() => {
        fetchData();
      }, 90000);
    }
  }, []);

  useEffect(() => {
    if (nextChallenges) {
      return;
    }

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchNewNextChallenge = () => {
    fetchData();
  };
  return { fetchNewNextChallenge, loadingNextChallenge, nextChallenges };
};
