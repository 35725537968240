import { FC } from "react";
import { ClientProps } from "../hooks/useClientData";
import { WinnerCard } from "../components/RACING/components/WinnerCard";
import { brand } from "../brandVariables/getBandVariables";

export const EventDetails: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.winners?.data;
  const upcomingChallenges = clientData.nextChallenges?.data;

  const uisToRender = {
    // JBL: <JBLLeaderboard clientData={clientData} />,
    LOGITECH: <WinnerCard clientData={clientData} />,
  };

  return (
    <div className="events-info">
      {winners && (
        <div className="winners">
          <h4 className="events-info__header">
            {/* <img src={trophy}></img> Winners */}
          </h4>
          <ul>
            {winners &&
              winners.slice(0, 1).map((event) => {
                return uisToRender[brand.client];
              })}
          </ul>
        </div>
      )}
    </div>
  );
};
