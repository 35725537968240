import { FC } from "react";
import { ClientProps } from "../../hooks/useClientData";
import { ReactComponent as BackGroundCrowns } from "./assets/background_crowns.svg";
import BaseUserAvatar from "../../components/reusable/BaseUserAvatar";
import { WinnerCard } from "./components/WinnerCard";

export const FALLGUYSEndScreen: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.winners.data;
  const winningDays = ["Friday", "Saturday", "Sunday"];
  console.log(winners);
  return (
    <div className="endscreen">
      <div className="endscreen__crowns">
        <BackGroundCrowns />
      </div>{" "}
      <div className="endscreen__heading">
        <h1>Thank you and congratulations to our WINNERS!</h1>
      </div>
      <ul className="endscreen__days">
        {winners && (
          <li className="winners">
            {winningDays.map((day) => {
              if (
                winners.filter((obj) => obj.winningDay === day).length !== 0
              ) {
                return (
                  <section>
                    <div className="winners__header">
                      <h2>{day}</h2>
                    </div>
                    <ul className="winners__list">
                      {winners.map((winner) => {
                        if (winner.winningDay === day) {
                          return (
                            <WinnerCard winner={winner} hasLabel={false} />
                          );
                        }
                      })}
                    </ul>
                  </section>
                );
              }
            })}
          </li>
        )}
      </ul>
    </div>
  );
};
