import { FC, useEffect } from "react";
import { ClientProps } from "../../../hooks/useClientData";
import { WinnerCard } from "./WinnerCard";
import { useTimer } from "../../../hooks/useCountDown";
import { MapsList } from "../../../consts/MapsLIst";
import { checkIfNextChallengeIsOngoing } from "../../../helperFunctions/checkIfNextChallengeIsOngoing";
import { TimerComp } from "./TimerComp";
import { ReactComponent as ElgigantenMap } from "../assets/austria_elgiganten.svg";
import { brand } from "@/brandVariables/getBandVariables";
export const Racing_meta: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const MapRender = MapsList["Austria"];

  const leaderboardLength = clientData.leaderboard?.length as number;

  const { time, startTimer, timeObj, isTimeOver, clearTimer } = useTimer(
    clientData.challenge.data?.endDate || undefined
  );

  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";

  const mapToRender = () => {
    if (brand.client === "ELGIGANTEN") {
      return <ElgigantenMap />;
    } else {
      return <MapRender />;
    }
  };
  return (
    <div className="meta">
      {leaderboardLength <= 3 && (
        <div className="meta__timer">
          <TimerComp time={time} />
        </div>
      )}
      <div className="meta__map">
        <div>
          <p>Track:</p>
          <h2 className={isElgiganten}>
            {/* {clientData.challenge?.data?.challengeAdditionalData
              .mapNames?.[0] || ""} */}
            Austria - RED BULL RING
          </h2>
        </div>
        <div>{mapToRender()}</div>
      </div>

      {clientData.winners.data?.length !== 0 && (
        <div className={`meta__winner`}>
          <div className={`winner__banner ${isElgiganten}`}>
            <p>previous winner</p>
          </div>
          <WinnerCard clientData={clientData} hasBanner={false} />
        </div>
      )}
    </div>
  );
};
